import {
  IError,
  LoadingOverlay,
  Spinner,
  formatDate,
  getGivenNameFromProfile,
  useToast,
  Vendors,
} from '@comptia-sso/core';
import ArrowForwardIosIconSharp from '@material-ui/icons/ArrowForwardIos';
import classNames from 'classnames';
import { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Hooks.
import {
  useProfile,
  useProfileEntitlements,
  useRedirectToVendor,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Styles.
import styles from './TrainingProducts.module.scss';

export const TrainingProductsView = (): ReactElement => {
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile, { isLoading: isLoadingProfile }] = useProfile();
  const [
    entitlements,
    { error: entitlementsError, isLoading: isLoadingEntitlements },
  ] = useProfileEntitlements(profile?.id);
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );
  const trackEvent = useTrackEvent('My Training Products');
  const trackOdpEvent = useTrackOdpEvent();

  useEffect(() => {
    if (entitlementsError) {
      popToast(
        (entitlementsError as IError)?.message || t('Toast.Error.Default'),
      );
    }
  }, [entitlementsError]);

  const renderUserEntitlements = () => {
    const activeEntitlements = renderEntitlements('active');
    const expiredEntitlements = renderEntitlements('expired');

    return activeEntitlements.concat(expiredEntitlements);
  };

  const renderEntitlements = (group: string) =>
    entitlements
      ?.sort(function (a, b) {
        return group === 'active'
          ? new Date(b.redemptionDate).valueOf() -
              new Date(a.redemptionDate).valueOf()
          : new Date(b.expirationDate).valueOf() -
              new Date(a.expirationDate).valueOf();
      })
      .map((entitlement) => {
        const expirationDate = new Date(entitlement.expirationDate);
        const isActive =
          expirationDate >= new Date() ||
          (!entitlement.expirationDate &&
            !entitlement.redemptionDate &&
            entitlement.vendor === Vendors.BenchPrep);
        let isEnabled = isActive;
        if (entitlement.vendor === Vendors.Populi) {
          isEnabled = entitlement.enableDateTime
            ? new Date(entitlement.enableDateTime) <= new Date()
            : false;
        }

        return (
          ((group === 'active' && isActive) ||
            (group === 'expired' && !isActive)) && (
            <a
              className={`${styles.product} ${!isActive ? styles.expired : ''}`}
              href="#"
              key={entitlement.id}
              onClick={async (e) => {
                e.preventDefault();
                trackEvent('Clicked', { label: entitlement.product?.title });
                trackOdpEvent(
                  'navigation',
                  'Launchpad Training - My Training',
                  entitlement.product?.title,
                );
                if (isActive && isEnabled) {
                  try {
                    // If vendor is populi, we want to redirect to canvas no populi. Otherwise use vendor tied to entitllement.
                    const vendor =
                      entitlement.vendor === Vendors.Populi
                        ? Vendors.Canvas
                        : entitlement.vendor;
                    await redirectToVendor(vendor, {
                      classroom: entitlement.product?.classroom,
                      productCode: entitlement.product?.vendorProductCode,
                    });
                  } catch (error) {
                    popToast(
                      (error as IError)?.message || t('Toast.Error.Default'),
                    );
                  }
                }
              }}
            >
              <div className={styles.productCourseName}>
                {entitlement.product?.title}
              </div>
              <div
                className={classNames(styles.productExpirationDate, {
                  [styles.expired]: !isActive,
                })}
              >
                <span>
                  {entitlement.vendor === Vendors.Populi && !isEnabled && (
                    <span className={styles.waiting}>
                      ({t('TrainingProducts.WaitingOnAgreement')})
                    </span>
                  )}
                  {!isActive
                    ? t('TrainingProducts.ExpiredOn')
                    : t('TrainingProducts.ExpiresOn')}
                </span>
                {formatDate(entitlement.expirationDate)}
              </div>
              <div className={styles.productCarret}>
                <ArrowForwardIosIconSharp />
              </div>
            </a>
          )
        );
      })
      .filter(Boolean);

  return (
    <>
      <Helmet>
        <title>My Training Products</title>
      </Helmet>
      <ContentLayout
        subtitle={t('TrainingProducts.Subheading')}
        title={getGivenNameFromProfile(profile)}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t('AccessYourTraining.Content2'),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('AccessYourTraining.Content3'),
          }}
        />
        {!isLoadingProfile && (
          <div className={styles.trainingProducts}>
            <hr />
            {isLoadingEntitlements ? (
              <Spinner className={styles.spinner} />
            ) : (
              <div className={styles.products}>
                {entitlements.length > 0 ? (
                  renderUserEntitlements()
                ) : (
                  <p>{t('TrainingProducts.NoProducts')}</p>
                )}
              </div>
            )}
          </div>
        )}
      </ContentLayout>
      <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />
    </>
  );
};
